<template>
  <v-row class="events-container">
    <v-col cols="12" sm="6" md="6" lg="4" class="event-container">
      <v-card @click="goToCup('hohesalve')">
        <v-img src="https://ebikecup.com/images/cups/EBCUP_Linkmodul_Hohe_Salve_01.jpg"/>
        <div class="elevation-3 event-date-container">{{$t('home.about.events.hohesalve.date')}}</div>
        <v-card-subtitle>
          <h3>{{$t('home.about.events.cup')}}</h3>
          <h1>{{$t('home.about.events.hohesalve.name')}}</h1>
        </v-card-subtitle>
        <v-card-actions>
          <v-btn outlined color="primary">
            {{$t('home.about.events.learnMore')}}
            <v-icon>{{icons.right}}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="6" lg="4" class="event-container">
      <v-card @click="goToCup('muerztal')">
        <v-img src="https://ebikecup.com/images/cups/EBCUP_Linkmodul_Muerztal_01.jpg"/>
        <div class="elevation-3 event-date-container">{{$t('home.about.events.muerztal.date')}}</div>
        <v-card-subtitle>
          <h3>{{$t('home.about.events.cup')}}</h3>
          <h1>{{$t('home.about.events.muerztal.name')}}</h1>
        </v-card-subtitle>
        <v-card-actions>
          <v-btn outlined color="primary">
            {{$t('home.about.events.learnMore')}}
            <v-icon>{{icons.right}}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="6" lg="4" class="event-container">
      <v-card @click="goToCup('entdeckerviertel')">
        <v-img src="https://ebikecup.com/images/cups/EBCUP_Linkmodul_Entdeckerviertel_01.jpg"/>
        <div class="elevation-3 event-date-container">{{$t('home.about.events.entdeckerviertel.date')}}</div>
        <v-card-subtitle>
          <h3>{{$t('home.about.events.cup')}}</h3>
          <h1>{{$t('home.about.events.entdeckerviertel.name')}}</h1>
        </v-card-subtitle>
        <v-card-actions>
          <v-btn outlined color="primary">
            {{$t('home.about.events.learnMore')}}
            <v-icon>{{icons.right}}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="6" lg="4" class="event-container">
      <v-card @click="goToCup('bozen')">
        <v-img src="https://ebikecup.com/images/cups/EBCUP_Linkmodul_Bozen_01.jpg"/>
        <div class="elevation-3 event-date-container">{{$t('home.about.events.bozen.date')}}</div>
        <v-card-subtitle>
          <h3>{{$t('home.about.events.cup')}}</h3>
          <h1>{{$t('home.about.events.bozen.name')}}</h1>
        </v-card-subtitle>
        <v-card-actions>
          <v-btn outlined color="primary">
            {{$t('home.about.events.learnMore')}}
            <v-icon>{{icons.right}}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="6" lg="4" class="event-container">
      <v-card @click="goToCup('burgenland')">
        <v-img src="https://ebikecup.com/images/cups/EBCUP_Linkmodul_Burgenland_01.jpg"/>
        <div class="elevation-3 event-date-container">{{$t('home.about.events.burgenland.date')}}</div>
        <v-card-subtitle>
          <h3>{{$t('home.about.events.cup')}}</h3>
          <h1>{{$t('home.about.events.burgenland.name')}}</h1>
        </v-card-subtitle>
        <v-card-actions>
          <v-btn outlined color="primary">
            {{$t('home.about.events.learnMore')}}
            <v-icon>{{icons.right}}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="6" lg="4" class="event-container">
      <v-card href="mailto:info@ebikeworldfederation.com">
        <v-img src="https://ebikecup.com/images/cups/EBCUP_Linkmodul_Deiner_Stadt_01.jpg"/>
        <v-card-subtitle>
          <h3>{{$t('home.about.events.yourCity')}}</h3>
        </v-card-subtitle>
        <v-card-actions class="justify-center">
          <v-btn outlined color="primary">
            {{$t('home.about.events.inquireNow')}}
            <v-icon>{{icons.right}}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import {mdiChevronRight} from '@mdi/js'
  export default {
    name: 'home-events',
    data() {
      return {
        icons: {
          right: mdiChevronRight,
        },
      };
    },
    methods: {
      goToCup(location) {
        this.$router.push({ name: location });
      }
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";
  .events-container {
    .event-container {
      .v-card {
        .event-date-container {
          position: absolute;
          background-color: $primary;
          color: white;
          padding: 2px 15px;
          top: 20px;
          right: -10px;
          font-size: 18px;
        }
        .v-image {
          height: 270px;
          @include MQ(M) {
            height: 250px;
          }
          @include MQ(S) {
            height: 250px;
          }
          @include MQ(XS) {
            height: 200px;
          }
        }
        .v-card__subtitle {
          color: $primary-text;
          margin-top: 0;
          font-weight: 700;
          font-style: italic;
          min-height: 110px;
          text-align: center;
          h1, h3 {
            font-weight: 700;
            text-transform: uppercase;
          }
          h3 {
            margin-top: 15px;
          }
          h1 {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 38px;
            @include MQ(M) {
              font-size: 35px;
            }
            @include MQ(S) {
              font-size: 30px;
            }
            @include MQ(XS) {
              font-size: 25px;
            }
          }
        }
        .v-card__actions {
          padding: 16px 16px 30px;
          text-align: center;
          display: block;
        }
        .v-btn {
          border-radius: 15px;
          border-width: 2px;
          padding: 0 5px;
          height: 30px;
          text-transform: none;
          .v-btn__content {
            padding: 0;
            height: 25px;
          }
        }
      }
    }
  }
</style>
