<template>
  <v-container>
    <v-row class="pt-5 pb-5 theme-container">
      <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1" id="media">
        <h1>{{$t('home.media.title')}}</h1>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
        <v-row>
          <v-col cols="12" sm="12" md="6" class="theme-wrapper" v-if="blog !== undefined">
            <v-card @click.stop.prevent="openThemeBlog()">
              <v-img :src="blog.teaser.imageUrl"/>
              <div class="theme-title">
                {{ blog.languages[0].title }}
              </div>
              <div class="theme-badge">
                BLOG
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="3" class="theme-wrapper">
            <v-card @click.stop.prevent="openPictureGallery()">
              <v-img src="https://dmaegt2h1boun.cloudfront.net/images/media/SD_EBWM_Bildergalerie_2019.jpg"/>
              <div class="theme-title">
                {{$t('home.media.gallery')}}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="3" class="theme-wrapper">
            <v-card @click.stop.prevent="openVideoPlaylist()">
              <v-img src="https://dmaegt2h1boun.cloudfront.net/images/media/SD_EBWM_Videogalerie_01.jpg"/>
              <div class="theme-title">
                {{$t('home.media.video')}}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar
      :timeout="snackbar.timeout"
      :bottom="snackbar.bottom"
      :color="snackbar.color"
      v-model="snackbar.value">
      {{ snackbar.text }}
      <v-btn text @click.native="snackbar.value = false">
        <v-icon>{{icons.close}}</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
  import {mdiPlayCircle, mdiClose} from '@mdi/js'
  export default {
    name: 'home-blogs',
    data() {
      return {
        snackbar: {
          value: false,
          text: '',
          color: 'success',
          timeout: 10000,
          bottom: true,
        },
        icons: {
          play: mdiPlayCircle,
          close: mdiClose
        },
        blog: undefined,
        newsletter: {
          loading: false,
          valid: undefined,
          email: undefined,
          name: undefined,
          emailRules: [
            v => !!v || this.$t('required'),
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('emailNotValid'),
          ],
        },
      };
    },
    mounted() {
      this.fetchBlog();
    },
    methods: {
      openThemeBlog() {
        window.open('https://specialdeal.at/blog/'.concat(this.blog.id), '_blank');
      },
      openPictureGallery() {
        this.$router.push({ name: 'images' });
      },
      openVideoPlaylist() {
        window.open('https://www.youtube.com/playlist?list=PL8jdDI7jKo3Wg_T9rl3EE5Ddh08Uu7ivE', '_blank');
      },
      fetchBlog() {
        this.$http.get('https://api.specialdeal.at/blogs/ebike').then(
          function success(data) {
            this.blog = data.body;
          },
        );
      }
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";

  .theme-container {
    padding-top: 45px;
    padding-bottom: 45px;

    .small-title {
      margin-bottom: 35px;
    }

    .theme-wrapper {
      height: 285px;
      position: relative;
      cursor: pointer;

      @include MQ(S) {
        height: 315px;
      }

      @include MQ(XS) {
        height: 140px;
      }

      .v-card {
        height: 100%;
        border-radius: 0;
        .v-image {
          height: 100%;
          object-position: center;
          object-fit: cover;
        }
        .theme-title {
          position: absolute;
          font-size: 16px;
          font-weight: 400;
          background-color: white;
          padding: 20px;
          border-radius: 10px;
          -webkit-box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),
          0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12) !important;
          box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),
          0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12) !important;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          max-width: 300px;
          text-align: center;

          @include MQ(XS) {
            font-size: 11px;
            padding: 10px;
          }
        }
        .v-icon {
          color: $primary !important;
          background-color: white;
          border-radius: 50%;
          position: absolute;
          left: 5px;
          bottom: 5px;
          height: 60px;
          width: 60px;
          svg {
            height: 60px;
            width: 60px;
          }
        }
        .theme-badge {
          cursor: pointer;
          position: absolute;
          padding: 15px 8px;
          border-radius: 50px !important;
          background-color: white;
          font-weight: 700;
          font-size: 15px;
          color: $secondary;
          border: 2px solid $secondary;
          bottom: 10px;
          left: 10px;
          -webkit-box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),
          0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12) !important;
          box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),
          0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12) !important;

          @include MQ(XS) {
            font-size: 10px;
            padding: 10px 6px;
          }
        }
      }
    }

    .newsletter-wrapper {
      padding-top: 50px;
      padding-bottom: 20px;
      .v-card {
        border-radius: 0;
        h1 {
          font-weight: 400;
          color: $primary-text;
          text-align: center;
        }
        .v-image {
          max-height: 100px;
          .v-image__image {
            background-size: contain;
          }
        }
        .newsletter-submit {
          border-radius: 10px;
          width: 100%;
          margin: 0;
          text-transform: none;
        }
        .newsletter-hint {
          color: $primary-text;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }
      }
    }
  }
</style>
